import React from 'react';
import {Icon} from 'semantic-ui-react';

function App() {
    return (
      <div className="footer">
      <p className="center-paragraph">Megan Jones &copy; 2023 </p>
      </div>
    );
  }
  
  export default App;
  