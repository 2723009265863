import React from 'react';
import Menu from './Menu.js';
import SocialMenu from './SocialMenu';
import {Grid, Segment} from 'semantic-ui-react';
import quill1 from '../svg/quill1.svg';
import ink1 from '../svg/ink1.svg';

function App() {
    return (
      <div>
        <Menu />
    <Grid>
        <Grid.Column>
        <div className="fontAlegreya2 text-container">
            <div>
            <img src="https://avatars3.githubusercontent.com/u/38929577?s=460&v=4" alt="Megan Jones" className="avatar-me" />
            </div>
            <h1 className="heading">My Story</h1>
            <p>
            <span className="fontFellEnglish">F</span>or as long as I can remember, I've been fascinated by language. I devoured the Teach Yourself series of books in elementary school, soaked up documentaries on hieroglyphics and ancient languages, and tried to teach myself Korean, Welsh, and other languages, all before I graduated high school. Because my family and I lived in Kenya for several years of my childhood, I also learned quite a bit of Swahili and grew even more interested in understanding all the sounds around me.
            </p>
            <p>
            Because of this passion for languages, I double majored in Comparative Literature and English at Indiana University. I couldn't think of much more exciting that getting to not only study literature but to get to study language and culture with it. I studied Spanish and Italian at the university, and also took Korean classes at the local Asian Culture Center. Throughout college, I also worked as an English as a foreign language teacher, mostly for South American business people. During this job, I improved my Spanish and dove headfirst into Portuguese, as I needed them both to effectively do my job.
            </p> 
            <p>
            I went on to get a Master's in English Literature at the University of Kansas, where I taught English rhetoric, composition, and writing to college students, and continued to teach English as a foreign language online. There, I continued to work on my Italian as I wrote my thesis on the letters of Bartolomeo Vanzetti.  
            </p> 
            <p>
            Upon finishing my Master's, I wasn't sure what I wanted to do. I was still teaching English -- which I really did love -- but I wanted something more. A dear friend introduced me to computer programming and I fell in love with it. I was never really exposed to anything like that, and I did not know it was something I'd even be interested in, but it captured my imagination because when you write a program, you are quite literally <b>using language to create action in the world</b>. I believed it was magic, and I wanted to know how to wield it.
            </p>
            <p>
            I went to a coding bootcamp and kept learning. Learning computer languages reignited my interest in human ones, and I worked on Portuguese alongside Python, and Bahasa Indonesia alongside JavaScript. It has been a few years since I finished that year at the bootcamp, and I have a job now, but the fire it reignited in me to want to learn and grow and understand how language works hasn't gone out. 
            </p>
            <p>
            Currently, I actively set aside time each week for maintaining or studying Italian, French, Spanish, Portuguese, Russian, German, Indonesian,and Korean. I'm interested in natural language processing, so in the computer space, I've been diving more into Python. Anyway, that's a little bit about me and why I'm so into languages.  
            </p>

        </div>
        </Grid.Column>
        </Grid>
        <SocialMenu />
      </div>
    );
  }
  
  export default App;
  